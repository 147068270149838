<template>
  <span
    class="material-symbols-outlined"
    @click="handleClick"
    :style="{
      cursor: pointer ? 'pointer' : 'default', color: '#' + color,
      fontVariationSettings: fill ? '\'FILL\' 1, \'wght\' 400, \'GRAD\' 0, \'opsz\' 24' : ''
    }"
  >
    {{ name }}
  </span>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    click: Function,
    pointer: Boolean,
    fill: Boolean,
    color: String
  },
  methods: {
    handleClick() {
      if (this.click != null) {
        this.click();
      }
    }
  }
};
</script>

<style>
span {
  vertical-align: middle;
}
</style>
